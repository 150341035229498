import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
import styled from "@emotion/styled"
import GlobalMenu from "./menu"

const Head = styled.header({
  background: "#444",
  position: "sticky",
  top: 0,
  zIndex: 1000,
})

const HeadInner = styled.div(
  {
    margin: "0 auto",
    padding: "14px 20px",
    "@media (max-width: 960px)": {
      padding: "14px 10px",
    },
  }
  // props =>
  //   props.location !== "home"
  //     ? { maxWidth: 960, padding: "14px 0" }
  //     : { padding: "14px 20px" }
)

const Header = ({ location, siteTitle }) => (
  <Head>
    <HeadInner location={location}>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <h1 style={{ margin: 0, lineHeight: 1, fontSize: "1.4rem" }}>
          <Link
            to="/"
            style={{
              color: "#fff",
              textDecoration: "none",
              display: "flex",
              alignItems: "center",
            }}
          >
            <svg
              x="0px"
              y="0px"
              viewBox="0 0 43.82 43.82"
              width="30"
              fill="#fff"
            >
              <g>
                <path
                  d="M4.92,8.23c0,0.06-0.06,0.16-0.19,0.29C4.58,8.64,4.44,8.76,4.3,8.87c-0.43,0.58-0.83,1.18-1.2,1.8
		c2.76-1.64,5.26-2.91,7.46-3.79C12.88,5.97,14.5,5.5,15.4,5.5c0.45,0,0.88,0.3,1.28,0.9c0.4,0.6,0.74,1.3,1.03,2.1
		c0.28,0.8,0.42,1.31,0.42,1.53c0,0.82-0.21,1.79-0.63,2.91c-0.42,1.12-0.99,2.42-1.7,3.9c-0.72,1.48-1.6,3.33-2.67,5.55
		c0.84-1.08,1.63-2.08,2.39-2.99c0.76-0.92,1.51-1.81,2.26-2.68c0.75-0.87,1.51-1.75,2.28-2.63c1.05-1.21,2.22-2.36,3.52-3.46
		c1.3-1.1,2.52-2,3.66-2.7c1.13-0.7,1.9-1.05,2.3-1.05c0.56,0,1.07,0.31,1.51,0.93c0.44,0.62,0.66,1.28,0.66,1.99
		c0,0.47-0.31,1.36-0.92,2.68c-0.61,1.32-1.5,3.01-2.68,5.07c-1.17,2.06-2.73,4.71-4.66,7.96c1.83-1.95,3.74-3.95,5.72-6.01
		c1.98-2.06,3.45-3.56,4.42-4.51c0.97-0.95,1.77-1.67,2.41-2.17c0.88-0.68,1.77-1.4,2.67-2.18c0.49-0.43,0.92-0.8,1.29-1.13
		C36.02,3.76,29.41,0,21.91,0C15.04,0,8.9,3.17,4.88,8.13C4.91,8.15,4.92,8.18,4.92,8.23z"
                />
                <path
                  d="M37.19,21.61c-1.61,2.39-2.83,4.57-3.66,6.54c-0.83,1.97-1.24,3.53-1.24,4.67c0,0.71,0.13,1.22,0.4,1.54
		c0.27,0.32,0.68,0.48,1.24,0.48c0.51,0,0.99-0.1,1.44-0.29c0.44-0.19,0.93-0.47,1.47-0.82c0.54-0.35,1.05-0.69,1.53-1.01
		c0.68-0.4,1.34-0.83,1.99-1.29c0.65-0.46,1.16-0.82,1.52-1.09c0.14-0.1,0.25-0.18,0.36-0.26c1.02-2.53,1.58-5.29,1.58-8.18
		c0-2.5-0.42-4.89-1.19-7.13C40.55,17.04,38.73,19.32,37.19,21.61z"
                />
                <path
                  d="M3.04,31.78c2.12-2.83,3.87-5.24,5.24-7.22c1.37-1.98,2.58-3.86,3.64-5.63c1.06-1.78,1.83-3.24,2.32-4.39
		c0.48-1.15,0.72-2.05,0.72-2.71c0-0.69-0.31-1.04-0.94-1.04c-0.51,0-1.39,0.33-2.63,0.99c-1.24,0.66-2.8,1.56-4.69,2.7
		c-1.89,1.14-3.41,2.02-4.55,2.62c-0.7,0.37-1.29,0.62-1.77,0.76C0.13,19.17,0,20.53,0,21.91c0,3.86,1,7.49,2.76,10.64
		C2.76,32.32,2.86,32.06,3.04,31.78z"
                />
                <path
                  d="M38.79,34.91c-1.07,0.88-2.14,1.67-3.2,2.37c-1.06,0.7-1.94,1.2-2.64,1.5c-0.7,0.3-1.3,0.45-1.8,0.45
		c-1.19,0-2.16-0.44-2.92-1.33c-0.76-0.88-1.13-2.03-1.13-3.43c0-1.61,0.33-3.2,0.98-4.78c0.65-1.58,1.58-3.21,2.79-4.89
		c1.21-1.68,2.72-3.57,4.54-5.66c1.35-1.54,2.03-2.41,2.03-2.61c0-0.11-0.04-0.21-0.12-0.29c-0.08-0.08-0.17-0.12-0.27-0.12
		c-0.31,0-1.25,0.68-2.82,2.03c-1.58,1.35-3.6,3.34-6.07,5.96c-2.47,2.62-5.14,5.74-8,9.36c-0.16,0.19-0.67,0.85-1.53,1.97
		c-0.86,1.12-1.47,1.85-1.82,2.18c-0.35,0.34-0.66,0.51-0.92,0.51c-0.45,0-0.84-0.45-1.17-1.36c-0.33-0.91-0.49-1.88-0.49-2.91
		c0-0.63,0.21-1.3,0.62-2.03c0.41-0.72,1.3-2.02,2.68-3.9c1.38-1.87,2.51-3.45,3.41-4.74c0.9-1.29,1.89-2.85,2.97-4.68
		c0.76-1.27,1.32-2.28,1.68-3.04c0.36-0.76,0.54-1.42,0.54-2c0-0.5-0.14-0.75-0.43-0.75c-0.61,0-1.76,0.82-3.45,2.45
		c-1.69,1.63-3.78,4.06-6.26,7.28c-2.49,3.22-5.08,6.94-7.78,11.17c-0.89,1.45-1.51,2.42-1.88,2.92c-0.11,0.15-0.23,0.27-0.35,0.38
		c4,4.25,9.67,6.91,15.96,6.91c7.9,0,14.83-4.19,18.68-10.46C39.94,33.95,39.34,34.46,38.79,34.91z"
                />
              </g>
            </svg>
            <span style={{ marginLeft: 16 }}>{siteTitle}</span>
          </Link>
        </h1>
        <div>
          <GlobalMenu />
        </div>
      </div>
    </HeadInner>
  </Head>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
