import React from "react"
import styled from "@emotion/styled"

const Foot = styled.footer({
  margin: "30px 10px",
  textAlign: "center",
})

const Footer = () => <Foot>© {new Date().getFullYear()} Makky</Foot>

export const View = Footer
