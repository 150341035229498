import { Link } from "gatsby"
import React from "react"
import styled from "@emotion/styled"

const Menu = styled.ul({
  display: "flex",
  fontSize: "1.4rem",
  listStyle: "none",
  margin: 0,
})

const MenuItem = styled.li({
  display: "block",
  margin: 0,
  padding: "0 10px",
})

const GlobalMenu = () => (
  <Menu>
    <MenuItem>
      <Link
        style={{
          color: "#fff",
          textDecoration: "none",
        }}
        to="/"
      >
        Work
      </Link>
    </MenuItem>
    <MenuItem>
      <Link
        style={{
          color: "#fff",
          textDecoration: "none",
        }}
        to="/about/"
      >
        About
      </Link>
    </MenuItem>
  </Menu>
)

export default GlobalMenu
